import styled from "styled-components";
import { StyledFormInput, StyledFormSelect } from "../../../commons/Form";
import { BP, Colors, Font, Rem } from "../../../commons/Theme";

export const StyledHeroModalContainer = styled.div`
  background-color: ${Colors.white};
  padding: ${Rem(25)} ${Rem(20)};

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: ${Rem(46)};

  @media (${BP.tablet}) {
    padding: ${Rem(50)} ${Rem(118)} ${Rem(62)} ${Rem(118)};
  }
`;

export const StyledHeroModalTitle = styled.div`
  font-family: ${Font.sans};
  font-weight: 700;
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};

  text-align: center;

  & > span {
    color: ${Colors.yellow};
  }

  @media (${BP.tablet}) {
    text-align: left;
  }
`;

export const StyledHeroModalFormContainer = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: ${Rem(16)};

  width: 100%;

  @media (${BP.tablet}) {
    gap: ${Rem(24)};
  }
`;

export const StyledModalFormFieldset = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  gap: ${Rem(16)};

  width: 100%;

  @media (${BP.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${Rem(30)};
  }
`;

export const StyledHeroModalFormInput = styled(StyledFormInput)`
  border-radius: 6px;

  padding: ${Rem(10)} ${Rem(24)};

  border: 0.4px solid rgba(51, 51, 51, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01);
`;

export const StyledHeroModalFormSelect = styled(StyledFormSelect)`
  border-radius: 6px;

  padding: 0;

  border: 0.4px solid rgba(51, 51, 51, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.01);

  > select {
    height: auto;
    border: none;

    border-radius: 6px;
    letter-spacing: 0;
  }
`;

export const StyledHeroModalFormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;

  font-family: ${Font.sans};

  a {
    color: ${Colors.yellow};
  }

  & > div {
    margin-bottom: ${Rem(32)};
  }

  @media (${BP.tablet}) {
    flex-wrap: nowrap;

    & > div {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }
`;
