import { Button } from "@3beehivetech/components";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import {
  StyledFormCheckbox,
  StyledFormCheckboxCheckmark,
  StyledFormCheckboxInput,
} from "../../../commons/Form";
import { DataContext } from "../../../contexts/DataContext";
import { LayoutContext } from "../../../contexts/LayoutContext";
import usePipeform from "../../../hooks/usePipeform";

import {
  StyledHeroModalContainer,
  StyledHeroModalTitle,
  StyledHeroModalFormContainer,
  StyledHeroModalFormInput,
  StyledHeroModalFormSelect,
  StyledModalFormFieldset,
  StyledHeroModalFormFooter,
} from "./style";

const FormContainer = () => {
  const { labels } = useContext(DataContext);
  const { sendPipeform, pipeformHowKnowUsOptions } = usePipeform();
  const { setModalContent } = useContext(LayoutContext);

  const formSchema = z.object({
    name: z.string().min(1),
    email: z.string().email().min(1),
    phone: z.string().min(1),
    companyName: z.string().min(1),
    numEmployees: z.preprocess(
      (a) => (a ? parseInt(a, 10) : null),
      z.number().nullish(),
    ),
    howKnowUsId: z.preprocess(
      (a) => (a ? parseInt(a, 10) : null),
      z.number().nullish(),
    ),
    //province: currentLanguageCode == "it" ? z.string().min(1) : z.string().nullish(),
    message: z.string().min(1),
    privacy: z.literal(true),
    acceptMarketing: z.boolean(),
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setFocus,
    reset,
  } = useForm({ resolver: zodResolver(formSchema) });

  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const fieldName = keys[0];
      const name = errors[fieldName].ref?.placeholder ?? fieldName;
      setFocus(fieldName);
      toast.error(`${name}: ${errors[fieldName].message}`, {
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [setFocus, errors]);

  return (
    <StyledHeroModalFormContainer
      id="contact3Bee"
      Validate
      onSubmit={handleSubmit((params) => {
        sendPipeform(params)
          .then((response) => {
            toast.configure();
            if (response.status === 202) {
              toast.success(labels.positiveMessages, {
                containerId: "Alert",
                position: toast.POSITION.BOTTOM_CENTER,
              });
              window.dataLayer.push({
                event: "CONTACT",
                description: "Richiesta di contatto",
              });
              window.dataLayer.push({
                event: "B2BContact",
              });
              reset();
            } else {
              toast.error(labels.negativeMessages, {
                containerId: "Alert",
                position: toast.POSITION.BOTTOM_CENTER,
              });
              if (typeof window !== "undefined") {
                window.dataLayer.push({
                  event: "exception",
                  description: "Errore Contatto CSR",
                  fatal: true,
                });
              }
            }
          })
          .catch(function () {
            toast.error(labels.negativeMessages, {
              containerId: "Alert",
              position: toast.POSITION.BOTTOM_CENTER,
            });
          });
        setModalContent(false);
        return false;
      })}
    >
      <StyledModalFormFieldset>
        <StyledHeroModalFormInput
          id="contacts-user"
          type="text"
          placeholder={labels.NAME_SURNAME}
          required
          {...register("name")}
        />
        <StyledHeroModalFormInput
          id="contacts-email"
          type="email"
          required
          placeholder={labels.MAIL_ADDRESS}
          {...register("email")}
        />
      </StyledModalFormFieldset>
      <StyledModalFormFieldset>
        <StyledHeroModalFormInput
          id="contacts-phone"
          type="tel"
          required
          placeholder={labels.PHONE_MOBILE}
          {...register("phone")}
        />
        <StyledHeroModalFormInput
          id="contacts-company-name"
          type="text"
          required
          placeholder={labels.companyName}
          {...register("companyName")}
        />
      </StyledModalFormFieldset>
      <StyledModalFormFieldset>
        <StyledHeroModalFormInput
          id="contacts-company-employees"
          type="number"
          pattern="[0-9]*"
          min="1"
          placeholder={labels.companyEmployees}
          {...register("numEmployees")}
        />
        <StyledHeroModalFormSelect>
          <select {...register("howKnowUsId")}>
            <option value="">{labels.howKnowUs}</option>
            {pipeformHowKnowUsOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.value}
              </option>
            ))}
          </select>
        </StyledHeroModalFormSelect>
      </StyledModalFormFieldset>
      <StyledHeroModalFormInput
        as="textarea"
        rows={3}
        id="contacts-request"
        required
        placeholder={labels.REQUEST}
        {...register("message")}
      />
      <StyledHeroModalFormFooter>
        <div>
          <StyledFormCheckbox>
            <span
              dangerouslySetInnerHTML={{ __html: labels.acceptPrivacy }}
            ></span>
            <StyledFormCheckboxInput {...register("privacy")} required />
            <StyledFormCheckboxCheckmark />
          </StyledFormCheckbox>
          <StyledFormCheckbox>
            {labels.acceptMarketing}
            <StyledFormCheckboxInput {...register("acceptMarketing")} />
            <StyledFormCheckboxCheckmark />
          </StyledFormCheckbox>
        </div>
        <Button>{labels.SEND_REQUEST}</Button>
      </StyledHeroModalFormFooter>
    </StyledHeroModalFormContainer>
  );
};

export default () => {
  return (
    <StyledHeroModalContainer>
      <StyledHeroModalTitle>
        Inizia il tuo percorso verso la <span>biodiversità!</span>
      </StyledHeroModalTitle>
      <FormContainer />
    </StyledHeroModalContainer>
  );
};
